.ReadSecretPanel {
    text-align: center;
    height: -webkit-fill-available;
}


.ReadSecretPanel-textarea {
    min-height: 30vh;
    min-width: 30vw;
}
  
  
@media only screen and (max-width: 768px) {
    .ReadSecretPanel-textarea {
        min-height: 45vh;
        min-width: 65vw;
    }
}