.ReadSecretPage {
    text-align: center;
    height: calc(90vh - 64px);
}
  
.ReadSecretPage-header {
  background-color: #FFFAFA;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ReadSecretPage-link {
  color: #61dafb;
}






