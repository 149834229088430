@import '../../index.css';

/* Header */

.HeroBigLogo {
    text-align: left;
    padding: 10%;
}

.HeroBigLogo__svg {
    color: #d1e5f9;
}

.LandingPageHeroSection {
    background-color: #4696e5;
    color: #d1e5f9;
    padding: 4.5em 0 2.5em 0 ;
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    background-image: url("images/overlay.png"), url("images/pattern-size1.svg");
    background-position: top left, center center;
    background-attachment: fixed, fixed;
    background-size: auto, cover;
    height: 90vh;
    min-height: 35em;
}

    .LandingPageHeroSection input[type="submit"],
    .LandingPageHeroSection input[type="reset"],
    .LandingPageHeroSection input[type="button"],
    .LandingPageHeroSection button,
    .LandingPageHeroSection .button {
        background-color: transparent;
        color: #ffffff !important;
        box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.25);
    }

        .LandingPageHeroSection input[type="submit"]:hover,
        .LandingPageHeroSection input[type="reset"]:hover,
        .LandingPageHeroSection input[type="button"]:hover,
        .LandingPageHeroSection button:hover,
        .LandingPageHeroSection .button:hover {
            background-color: rgba(255, 255, 255, 0.075);
        }

        .LandingPageHeroSection input[type="submit"]:active,
        .LandingPageHeroSection input[type="reset"]:active,
        .LandingPageHeroSection input[type="button"]:active,
        .LandingPageHeroSection button:active,
        .LandingPageHeroSection .button:active {
            background-color: rgba(255, 255, 255, 0.2);
        }

        .LandingPageHeroSection input[type="submit"].primary,
        .LandingPageHeroSection input[type="reset"].primary,
        .LandingPageHeroSection input[type="button"].primary,
        .LandingPageHeroSection button.primary,
        .LandingPageHeroSection .button.primary {
            box-shadow: none;
            background-color: #ffffff;
            color: #4696e5 !important;
        }

    .LandingPageHeroSection label {
        color: #ffffff;
    }

    .LandingPageHeroSection input[type="text"],
    .LandingPageHeroSection input[type="password"],
    .LandingPageHeroSection input[type="email"],
    .LandingPageHeroSection select,
    .LandingPageHeroSection textarea {
        color: inherit;
        background-color: rgba(255, 255, 255, 0.075);
        border-color: rgba(255, 255, 255, 0.25);
    }

        .LandingPageHeroSection input[type="text"]:focus,
        .LandingPageHeroSection input[type="password"]:focus,
        .LandingPageHeroSection input[type="email"]:focus,
        .LandingPageHeroSection select:focus,
        .LandingPageHeroSection textarea:focus {
            border-color: #ffffff;
            box-shadow: 0 0 0 1px #ffffff;
        }

    .LandingPageHeroSection .select-wrapper:before {
        color: rgba(255, 255, 255, 0.25);
    }

    .LandingPageHeroSection input[type="checkbox"] + label,
    .LandingPageHeroSection input[type="radio"] + label {
        color: #d1e5f9;
    }

        .LandingPageHeroSection input[type="checkbox"] + label:before,
        .LandingPageHeroSection input[type="radio"] + label:before {
            background-color: rgba(255, 255, 255, 0.075);
            border-color: rgba(255, 255, 255, 0.25);
        }

    .LandingPageHeroSection input[type="checkbox"]:checked + label:before,
    .LandingPageHeroSection input[type="radio"]:checked + label:before {
        background-color: #ffffff;
        border-color: #ffffff;
        color: #4696e5;
    }

    .LandingPageHeroSection input[type="checkbox"]:focus + label:before,
    .LandingPageHeroSection input[type="radio"]:focus + label:before {
        border-color: #ffffff;
        box-shadow: 0 0 0 1px #ffffff;
    }

    .LandingPageHeroSection ::-webkit-input-placeholder {
        color: #b5d5f5 !important;
    }

    .LandingPageHeroSection :-moz-placeholder {
        color: #b5d5f5 !important;
    }

    .LandingPageHeroSection ::-moz-placeholder {
        color: #b5d5f5 !important;
    }

    .LandingPageHeroSection :-ms-input-placeholder {
        color: #b5d5f5 !important;
    }

    .LandingPageHeroSection .formerize-placeholder {
        color: #b5d5f5 !important;
    }

    .LandingPageHeroSection .icon {
        color: #b5d5f5;
    }

    .LandingPageHeroSection .image {
        box-shadow: 0 0 0 4px #4696e5, 0 0 0 5px rgba(255, 255, 255, 0.25);
    }

        .LandingPageHeroSection .image.phone {
            box-shadow: none;
        }

            .LandingPageHeroSection .image.phone .inner, .LandingPageHeroSection .image.phone:before, .LandingPageHeroSection .image.phone:after {
                border-color: rgba(255, 255, 255, 0.25);
            }

            .LandingPageHeroSection .image.phone .inner:before {
                background: rgba(255, 255, 255, 0.25);
            }

            .LandingPageHeroSection .image.phone .inner:after {
                border-color: rgba(255, 255, 255, 0.25);
            }

    .LandingPageHeroSection ul.alt > li {
        border-top-color: rgba(255, 255, 255, 0.25);
    }

    .LandingPageHeroSection table tbody tr {
        border-color: rgba(255, 255, 255, 0.25);
    }

        .LandingPageHeroSection table tbody tr:nth-child(2n + 1) {
            background-color: rgba(255, 255, 255, 0.075);
        }

    .LandingPageHeroSection table th {
        color: #ffffff;
    }

    .LandingPageHeroSection table thead {
        border-bottom-color: rgba(255, 255, 255, 0.25);
    }

    .LandingPageHeroSection table tfoot {
        border-top-color: rgba(255, 255, 255, 0.25);
    }

    .LandingPageHeroSection table.alt tbody tr td {
        border-color: rgba(255, 255, 255, 0.25);
    }

    .LandingPageHeroSection input, .LandingPageHeroSection select, .LandingPageHeroSection textarea {
        color: #d1e5f9;
    }

    .LandingPageHeroSection a {
        color: inherit;
        border-bottom-color: rgba(255, 255, 255, 0.5);
        color: #ffffff;
    }

    .LandingPageHeroSection strong, .LandingPageHeroSection b {
        color: #ffffff;
    }

    .LandingPageHeroSection h1, .LandingPageHeroSection h2, .LandingPageHeroSection h3, .LandingPageHeroSection h4, .LandingPageHeroSection h5, .LandingPageHeroSection h6 {
        color: #ffffff;
    }

    .LandingPageHeroSection blockquote {
        border-left-color: rgba(255, 255, 255, 0.25);
    }

    .LandingPageHeroSection code {
        background: rgba(255, 255, 255, 0.075);
        border-color: rgba(255, 255, 255, 0.25);
    }

    .LandingPageHeroSection hr {
        border-bottom-color: rgba(255, 255, 255, 0.25);
    }

    .LandingPageHeroSection h1 {
        font-size: 3.25em;
        font-weight: bold;
        margin-bottom: 0.5em;
    }

    .LandingPageHeroSection p {
        font-size: 1.5em;
        line-height: 1.35em;
    }

        .LandingPageHeroSection p br {
            display: inline;
        }

    .LandingPageHeroSection .content {
        -moz-transition: opacity 0.5s ease, -moz-transform 1s ease;
        -webkit-transition: opacity 0.5s ease, -webkit-transform 1s ease;
        -ms-transition: opacity 0.5s ease, -ms-transform 1s ease;
        transition: opacity 0.5s ease, transform 1s ease;
        -moz-transform: translateX(0);
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
        text-align: right;
        margin: -3.5em 4em 0 0;
    }

        .LandingPageHeroSection .content > :last-child {
            margin-bottom: 0;
        }

        .LandingPageHeroSection .content .actions {
            -moz-justify-content: -moz-flex-end;
            -webkit-justify-content: -webkit-flex-end;
            -ms-justify-content: -ms-flex-end;
            justify-content: flex-end;
        }

    .LandingPageHeroSection .image {
        -moz-transition: opacity 1s ease, -moz-transform 1s ease;
        -webkit-transition: opacity 1s ease, -webkit-transform 1s ease;
        -ms-transition: opacity 1s ease, -ms-transform 1s ease;
        transition: opacity 1s ease, transform 1s ease;
        -moz-transform: translateY(0);
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

        .LandingPageHeroSection .image img {
            -moz-transition: opacity 0.75s ease;
            -webkit-transition: opacity 0.75s ease;
            -ms-transition: opacity 0.75s ease;
            transition: opacity 0.75s ease;
            -moz-transition-delay: 0.75s;
            -webkit-transition-delay: 0.75s;
            -ms-transition-delay: 0.75s;
            transition-delay: 0.75s;
            opacity: 1;
        }

    @media screen and (max-width: 1280px) {

        .LandingPageHeroSection {
            background-image: url("images/overlay.png"), url("images/pattern-size2.svg");
        }

    }

    @media screen and (max-width: 980px) {

        .LandingPageHeroSection {
            height: auto;
            min-height: 0;
        }

            .LandingPageHeroSection .content {
                margin: -2em 4em 0 0;
            }

                .LandingPageHeroSection .content .actions {
                    -moz-justify-content: center;
                    -webkit-justify-content: center;
                    -ms-justify-content: center;
                    justify-content: center;
                }

    }

    @media screen and (max-width: 980px) and (orientation: portrait) {

        .LandingPageHeroSection {
            padding: 6em 3em 4em 3em ;
            -moz-flex-direction: column-reverse;
            -webkit-flex-direction: column-reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
        }

            .LandingPageHeroSection .content {
                display: block;
                width: 100%;
                text-align: center;
                margin: 0 0 2em 0;
            }

            .LandingPageHeroSection .image {
                overflow: hidden;
            }

    }

    @media screen and (max-width: 736px) {
        .HeroBigLogo {
            display: none;
        }

        .LandingPageHeroSection {
            background-image: url("images/overlay.png"), url("images/pattern-size3.svg");
        }

            .LandingPageHeroSection h1 {
                font-size: 2em;
            }

            .LandingPageHeroSection p {
                font-size: 1em;
                line-height: inherit;
            }

    }

    @media screen and (max-width: 736px) and (orientation: landscape) {

        .LandingPageHeroSection {
            padding: 2em 2em 0.1em 2em ;
        }

            .LandingPageHeroSection .content {
                margin: 0 2em 2em 0;
            }

            .LandingPageHeroSection .image {
                font-size: 0.8em;
                width: 15em;
                max-width: 20vw;
            }

                .LandingPageHeroSection .image .inner, .LandingPageHeroSection .image:before, .LandingPageHeroSection .image:after {
                    font-size: 0.8em;
                }

    }

    @media screen and (max-width: 736px) and (orientation: portrait) {

        .LandingPageHeroSection {
            padding: 3em 1.5em 1em 1.5em ;
        }

            .LandingPageHeroSection .image {
                font-size: 0.6em;
                max-width: 60vw;
            }

    }

@media screen and (max-width: 480px) and (orientation: landscape) {

    .LandingPageHeroSection {
        padding: 2em 1.5em 0.1em 1.5em ;
    }

        .LandingPageHeroSection .image {
            font-size: 0.7em;
        }

        .LandingPageHeroSection .actions {
            font-size: 0.8em;
        }

}

@media screen and (max-width: 480px) and (orientation: portrait) {

    .LandingPageHeroSection {
        padding: 2em 1em 0.1em 1em ;
    }

}

    body.is-mobile .LandingPageHeroSection {
        background-attachment: scroll, scroll;
    }

    body.is-preload .LandingPageHeroSection .content {
        -moz-transform: translateX(2em);
        -webkit-transform: translateX(2em);
        -ms-transform: translateX(2em);
        transform: translateX(2em);
        opacity: 0;
    }

    body.is-preload .LandingPageHeroSection .image {
        -moz-transform: translateY(4em);
        -webkit-transform: translateY(4em);
        -ms-transform: translateY(4em);
        transform: translateY(4em);
        opacity: 0;
    }

        body.is-preload .LandingPageHeroSection .image img {
            opacity: 0;
        }

@media screen and (max-width: 980px) and (orientation: portrait) {

    body.is-preload .LandingPageHeroSection .content {
        -moz-transform: none;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        opacity: 1;
    }

    body.is-preload .LandingPageHeroSection .image {
        -moz-transform: translateY(2em);
        -webkit-transform: translateY(2em);
        -ms-transform: translateY(2em);
        transform: translateY(2em);
    }

}