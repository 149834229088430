.InsertSecretBar {
  border-radius: 5px;
  margin: 5% 5% 5% 5%;
  min-width: 40%;
  max-width: 60%;
  /* max-height: 70vh; */
  /* padding: 5%; */
  background-color: white;
  text-align: center;
}

.InsertSecretBar-link {
  color: #61dafb;
}

.InsertSecretBar-input-panel {
  text-align: center;
  padding: 24px;
}


.InsertSecretBar-output-panel {
  text-align: center;
  padding: 24px;
}


@media only screen and (max-width: 768px) {
  .InsertSecretBar {
    width: 80%;
    max-width: 80%;
    max-height: 80vh;
    margin: 10% 10% 10%;
  }
}