@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap');
@import '../../general-style.css';


.TopBar-header {
    /* border-bottom: 1px solid #E2E8F0; */
    background-color: var(--websiteMainColor);

    height: 64px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-size: 62.5%;
    font-family: 'Roboto', sans-serif;
}

.TopBar-header a {
    text-decoration: none;
    color: #FFFFEE;
    border-bottom: none;
}

.TopBar-header li {
    list-style: none;
}

.TopBar-navbar {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 1rem 1.5rem;
}




.TopBar-hamburger {
    display: none;
}

.TopBar-bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    /* background-color: #101010; */
    background-color: #FFFFFF;
}





.TopBar-nav-menu {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.TopBar-nav-item {
    margin-left: 5rem;
}

.TopBar-nav-link{
    font-size: 1.6rem;
    font-weight: 400;
    color: #475569;
}

.TopBar-nav-link:hover{
    color: #482ff7;
}

.TopBar-nav-logo {
    font-size: 2.5rem;
    font-weight: 500;
    color: #482ff7;
}




@media only screen and (max-width: 768px) {
    .TopBar-nav-menu {
        position: absolute;
        left: -100%;
        top: 64px;
        flex-direction: column;
        background-color: var(--websiteMainColorLight);
        z-index: 10;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .TopBar-nav-menu.active {
        left: 0;
    }

    .TopBar-nav-item {
        margin: 2.5rem 0;
    }

    .TopBar-hamburger {
        display: block;
        cursor: pointer;
    }

    .TopBar-hamburger.active .TopBar-bar:nth-child(2) {
        opacity: 0;
    }

    .TopBar-hamburger.active .TopBar-bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .TopBar-hamburger.active .TopBar-bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
}